// landing
export { default as SaveMoney } from "./save-money.svg";
export { default as Case } from "./case.svg";
export { default as Wallet } from "./wallet.svg";
export { default as UserManager } from "./user-manager.svg";
export { default as UserSupport } from "./user-support.svg";
export { default as BoxProducts } from "./box-products.svg";
export { default as Like } from "./like.svg";
export { default as Star } from "./star.svg";
export { default as Timer } from "./timer.svg";
export { default as Search } from "./search.svg";
export { default as Orders } from "./orders.svg";
export { default as Integration } from "./integration.svg";
export { default as Delivery } from "./delivery.svg";
export { default as AutodocLogo } from "./autodoc-logo.svg";
export { default as TrefoilKnot } from "./trefoil-knot.svg";
export { default as BoxMoving } from "./box-moving.svg";
export { default as Crown } from "./crown.svg";
export { default as ThreeBoxes } from "./three-boxes.svg";
export { default as ThriftBox } from "./thrift-box.svg";
export { default as ShevronRight } from "./shevron-right.svg";
export { default as ShevronLeft } from "./shevron-left.svg";
export { default as LoadingOutlined } from "./loading-outlined.svg";

// countries
export { default as CountryGb } from "./country-gb.svg";
export { default as CountryAt } from "./country-at.svg";
export { default as CountryBe } from "./country-be.svg";
export { default as CountryBg } from "./country-bg.svg";
export { default as CountryCz } from "./country-cz.svg";
export { default as CountryDk } from "./country-dk.svg";
export { default as CountryEe } from "./country-ee.svg";
export { default as CountryFi } from "./country-fi.svg";
export { default as CountryFr } from "./country-fr.svg";
export { default as CountryDe } from "./country-de.svg";
export { default as CountryGr } from "./country-gr.svg";
export { default as CountryNl } from "./country-nl.svg";
export { default as CountryHu } from "./country-hu.svg";
export { default as CountryIe } from "./country-ie.svg";
export { default as CountryIt } from "./country-it.svg";
export { default as CountryLv } from "./country-lv.svg";
export { default as CountryLt } from "./country-lt.svg";
export { default as CountryLu } from "./country-lu.svg";
export { default as CountryNo } from "./country-no.svg";
export { default as CountryPl } from "./country-pl.svg";
export { default as CountryPt } from "./country-pt.svg";
export { default as CountryRo } from "./country-ro.svg";
export { default as CountrySk } from "./country-sk.svg";
export { default as CountrySi } from "./country-si.svg";
export { default as CountryEs } from "./country-es.svg";
export { default as CountrySe } from "./country-se.svg";
export { default as CountryCh } from "./country-ch.svg";
export { default as CountryHr } from "./country-hr.svg";
export { default as CountryMt } from "./country-mt.svg";
export { default as CountryCy } from "./country-cy.svg";
export { default as CountryFo } from "./country-fo.svg";

// emoji
export { default as EmojiSmile } from "./emoji-smile.svg";

// Inline icons
export { default as DownOutlined } from "./down-outlined.svg";
export { default as UploadOutlined } from "./upload-outlined.svg";
export { default as UpOutlined } from "./up-outlined.svg";
export { default as StepProcess } from "./step-process.svg";
export { default as StepWait } from "./step-wait.svg";
export { default as StepFlag } from "./step-flag.svg";
export { default as ExclamationCircleOutlined } from "./exclamation-circle-outlined.svg";
export { default as InfoCircle } from "./info-circle.svg";
export { default as InfoSuccess } from "./info-success.svg";
export { default as InfoWarning } from "./info-warning.svg";
export { default as InfoError } from "./info-error.svg";
export { default as CloseX } from "./close-x.svg";
export { default as Bell } from "./bell.svg";
export { default as Cart } from "./cart.svg";
export { default as CartFilled } from "./cart-filled.svg";
export { default as UserProfile } from "./user-profile.svg";
export { default as Support } from "./support.svg";
export { default as Gear } from "./gear.svg";
export { default as AlertWarning } from "./alert-warning.svg";
export { default as Bin } from "./bin.svg";
export { default as ArrowUpCounter } from "./arrow-up-counter.svg";
export { default as ArrowDownCounter } from "./arrow-down-counter.svg";
export { default as Basket } from "./basket.svg";
export { default as Plus } from "./plus.svg";
export { default as CheckCircleFilled } from "./check-circle-filled.svg";
export { default as CloseXCircle } from "./close-x-circle.svg";
export { default as Download } from "./download.svg";
export { default as File } from "./file.svg";
export { default as FileOutline } from "./file-outline.svg";
export { default as QuestionMarkCircle } from "./question-mark-circle.svg";
export { default as Repeat } from "./repeat.svg";
export { default as ChevronLeft } from "./chevron-left.svg";
export { default as ChevronRight } from "./chevron-right.svg";
export { default as ChevronDown } from "./chevron-down.svg";
export { default as Warning } from "./warning.svg";
export { default as WarningLabel } from "./warning-label.svg";
export { default as Stop } from "./stop.svg";
export { default as PlusOutlined } from "./plus-outlined.svg";
export { default as MinusOutlined } from "./minus-outlined.svg";
export { default as EditOutlined } from "./edit-outlined.svg";
export { default as RarTypesWarranty } from "./rar-types-warranty.svg";
export { default as RarTypesShipping } from "./rar-types-shipping.svg";
export { default as TimePad } from "./time-pad.svg";
export { default as SearchOutlined } from "./search-outlined.svg";
export { default as InfoCircleFilled } from "./info-circle-filled.svg";
export { default as InfoFilled } from "./info-filled.svg";
export { default as Lock } from "./lock.svg";

// Credit cards
export { default as Visa } from "./visa.svg";
export { default as Mastercard } from "./mastercard.svg";
export { default as AmericanExpress } from "./american-express.svg";
export { default as Discover } from "./discover.svg";
export { default as CarteBancaire } from "./cartebancaire.svg";
