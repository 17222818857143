export const BASKET_DELIVERY_OPTIONS = {
  byParcel: "byParcel",
  byPallet: "byPallet",
  fromWarehouse: "fromWarehouse",
} as const;

export const BASKET_STEP_NAMES = {
  basket: 0,
  deliveryAddress: 1,
  checkout: 2,
};

export const BASKET_METHODS_PRIMARY = {
  bankTransfer: "BankTransfer",
  creditCard: "CreditCard",
} as const;
