// Core
import { makeAutoObservable } from "mobx";

// Definitions
import type { PaymentMethodPrimaryType } from "@/client/domains/basket/types";
import { BASKET_METHODS_PRIMARY } from "@/client/domains/basket/constants";
import type { Nullable } from "@/common/types/basic";

export type BasketPaymentStoreType = {
  orderId: Nullable<number>;
  setOrderId: (value: Nullable<number>) => void;
  activePrimaryMethod: PaymentMethodPrimaryType["paymentMethodPrimary"];
  setActivePrimaryMethod: (value: PaymentMethodPrimaryType["paymentMethodPrimary"]) => void;
  resetActivePrimaryMethod: () => void;
};

const BasketPaymentStore = () =>
  makeAutoObservable<BasketPaymentStoreType>(
    {
      orderId: null,
      setOrderId(value: Nullable<number>) {
        this.orderId = value;
      },
      activePrimaryMethod: BASKET_METHODS_PRIMARY.bankTransfer,
      setActivePrimaryMethod(value) {
        this.activePrimaryMethod = value;
      },
      resetActivePrimaryMethod() {
        this.activePrimaryMethod = BASKET_METHODS_PRIMARY.bankTransfer;
      },
    },
    {},
    { autoBind: true },
  );

export default BasketPaymentStore;
