"use client";
// Core
import { createContext } from "react";

// Stores
import ControlStore from "./control-store";
import SignupStepsStore from "@/client/domains/signup/signup-profile/store";
import GeoStore from "./geo-store";
import UserStore from "@/client/domains/user/store";
import MassSearchStore from "@/client/domains/dashboard/store";
import ConsentCookiesStore from "@/client/domains/user/consent-cookies/store";
import BasketPaymentStore from "@/client/domains/basket/store";

const store = {
  controls: ControlStore(),
  geo: GeoStore(),
  signupSteps: SignupStepsStore(),
  user: UserStore(),
  massSearch: MassSearchStore(),
  consentCookies: ConsentCookiesStore(),
  basketPayment: BasketPaymentStore(),
};

export const StoreContext = createContext(store);

export default store;
